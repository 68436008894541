<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="68px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主手机">
				<el-input class="el_input" v-model="form.shipper_tel" placeholder="货主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货主公司">
				<el-input class="el_input" v-model="form.shipper_company_name" placeholder="货主公司名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车主手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="车主手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未发车" value="1"></el-option>
					<el-option label="已发车" value="2"></el-option>
					<el-option label="已到货" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.payed_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="部分支付" value="2"></el-option>
					<el-option label="支付完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settled_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未结算" value="1"></el-option>
					<el-option label="部分结算" value="2"></el-option>
					<el-option label="结算完毕" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="上报状态">
				<el-select class="el_input" v-model="form.upl_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未上报" value="1"></el-option>
					<el-option label="运单上报成功" value="2"></el-option>
					<el-option label="运单上报失败" value="3"></el-option>
					<el-option label="流水上报成功" value="4"></el-option>
					<el-option label="流水上报失败" value="5"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<!-- <el-button type="primary" @click="ser_form_open">更多条件</el-button> -->
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="tord_export_open">导出搜索结果</el-button>
				<el-button type="primary" @click="tord_export_open">批量调度分配</el-button>
			</el-form-item>
		</el-form>
		<div class="total_info item">
			<span>共:{{list.total}}条</span>
			<!-- <span>总计:{{5}}元</span> -->
			<span>总计:{{list.total_freight}}元</span>
			<span v-if="list.total_service>0">服务费{{list.total_service}}元</span>
		</div>
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" @selection-change="select_table">
				 <el-table-column type="selection" width="40" align="center">
                </el-table-column>
				<el-table-column label="运单/组别/上游单号" width="170px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">组别:{{scope.row.group1_num?scope.row.group1_num:"无"}}</div>
						<div class="tab_line_item">上游:{{scope.row.out_trade_num?scope.row.out_trade_num:"无"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">发货地:{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}/{{scope.row.case_other_obj.addr_info?scope.row.case_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">卸货地:{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}/{{scope.row.aim_other_obj.addr_info?scope.row.aim_other_obj.addr_info:'无'}}</div>
						<div class="tab_line_item">发车日期:{{scope.row.case_other_obj.start_time}}{{(scope.row.case_other_obj.start_time_is_check_text)}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/重量/体积" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_type}}/{{scope.row.cargo_name}}</div>
						<div class="tab_line_item">{{scope.row.cargo_weight==0?"无重量":scope.row.cargo_weight+"吨"}}/{{scope.row.cargo_volume==0?"无体积":scope.row.cargo_volume+"方"}}</div>
						<div class="tab_line_item">{{scope.row.settlement_type_text}}/{{scope.row.is_need_return_text}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费/支付状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">应收:{{scope.row.cost_info.freight_total_text}}</div>
						<div class="tab_line_item">应付:{{scope.row.cost_info.give_carrier_total_text}}</div>
						<div class="tab_line_item">
							{{scope.row.payed_status_text}} 
							<el-button class="btn_left" @click="tord_payed_list(scope.row,'tord_pay_list')" size="mini" type="text">支付记录</el-button>
						</div>
						<div class="tab_line_item">
							{{scope.row.settled_status_text}} 
							<el-button class="btn_left" @click="tord_payed_list(scope.row,'tord_pay_list')" size="mini" type="text">结算记录</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_info.name}}/{{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.shipper_company_info.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车主/司机/货车信息" width="180px">
					<template slot-scope="scope">
						<div class="tab_line_item">车主:{{scope.row.truck_owner_info.name}}/{{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机:{{scope.row.driver_info.name}}/{{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.truck_plate_num}}{{scope.row.truck_info.truck_type_name_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作网点" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">姓名:{{scope.row.outlets_info.name?scope.row.outlets_info.name:'无'}}</div>
						<div class="tab_line_item">电话:{{scope.row.outlets_info.tel?scope.row.outlets_info.tel:'无'}}</div>
						<div class="tab_line_item">网点:{{scope.row.outlets_info.outlets_name?scope.row.outlets_info.outlets_name:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单状态" width="130px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.status_text}}</div>
						<div class="tab_line_item">
							{{scope.row.lock_status_text}} 
							<el-button @click="tord_unlock(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==1">解锁</el-button>
							<el-button @click="tord_lock_open(scope.row)" size="mini" type="text" v-if="scope.row.lock_status==2" style="color:red">锁定</el-button>
						</div>
						<div class="tab_line_item">{{scope.row.upl_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="时效信息" width="155px">
					<template slot-scope="scope">
						<div class="tab_line_item">接:{{scope.row.creat_time_text}}</div>
						<div class="tab_line_item">发:{{scope.row.start_time_text}}</div>
						<div class="tab_line_item">到:{{scope.row.arrived_time_text}}</div>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="132">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status==1" class="btn_left" @click="tord_start(scope.row)" size="mini" type="text">发车</el-button>
						<el-button v-if="scope.row.status==2" class="btn_left" @click="tord_arrived(scope.row)" size="mini" type="text">到货</el-button>
						<el-button v-if="scope.row.status==3" class="btn_left" @click="tord_upl_open(scope.row)" size="mini" type="text">上报</el-button>
						<el-button @click="voucher_show(scope.row)" size="mini" type="text" >凭证</el-button>
						<el-button @click="tord_cancel(scope.row)" size="mini" type="text" style="color:red">取消</el-button>
						<el-button @click="tord_edit(scope.row)" class="btn_left" size="mini" type="text">运单修改</el-button>
						<el-button @click="freight_edit_open(scope.row)" size="mini" type="text" >运费修改</el-button>
						<el-button @click="tord_set_open(scope.row)" class="btn_left" size="mini" type="text">参数</el-button>
						<el-button @click="truck_trajectory_open(scope.row)" size="mini" type="text">轨迹</el-button>
						<el-button @click="settle_view_open(scope.row)" size="mini" type="text" >结算</el-button>
						<el-button @click="dispatch_branch(scope.row)" size="mini" type="text" >调度分配</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 调度分配 -->
		<outletslist :is_show="outelts.is_show"></outletslist>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import trucktTrajectory from '@/components/map/amap/trajectory.vue'
	import outletslist from './outlets_list.vue'
	export default {
		components:{
			trucktTrajectory,
			outletslist
		},
		data() {
			return {

				//搜索界面是否打开
				ser_form_is_show:0,

				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
					shipper_tel:'',//货主手机号
					shipper_company_name:'',//货主公司名称
					truck_owner_tel:'',//车主手机号
					driver_tel:'',//司机手机号
					truck_plate_num:'',//车牌号
					status:'',//状态
					payed_status:'',//支付状态
					settled_status:'',//结算状态
					upl_status:'',//上报状态
				},
				// 调度参数
				outelts:{
					is_show:0,
					truck_tord_num:[],
				},
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					total_freight:'',//总金额
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//凭证相关
				voucher:{
					is_show:0,
					truck_tord_num:''
				},

				//结算界面
				settle:{
					is_show:0,
					tord_info:{}
				},

				//修改相关
				edit:{
					is_show:0,
					tord_info:{}
				},
				
				//运费修改相关
				freight_edit:{
					is_show:0,
					tord_info:{}
				},

				//运单上报记录
				upl_list:{
					is_show:0,
					tord_from:'act',
					tord_info:{}
				},

				//货车轨迹
				trajectory:{
					is_show:false,
					case_county:null,
					aim_county:null,
					start_time:null,
					end_time:null,
					truck_plate_num:null,
				},

				//运单锁定界面
				lock:{
					is_show:0,
					truck_tord_num:'',
				},

				//导出搜索结果界面
				tord_export:{
					is_show:0,
					rull:{}
				},
				
				//运单参数界面
				tord_set:{
					is_show:0,
					truck_tord_num:'',
				}
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {
			//勾选数据
			select_table(item){
				var truck_tord_num = []
				item.forEach(item => {
					truck_tord_num.push(item.truck_tord_num)
				});
				this.outelts.truck_tord_num = truck_tord_num
			},
			//单个调度分配
			dispatch_branch(item){
				this.outelts.is_show ++
				var truck_tord_num = []
				truck_tord_num.push(item.truck_tord_num)
				this.outelts.truck_tord_num = truck_tord_num
			},
			//轨迹
			truck_trajectory_open(item){
				if(item.status==1){
					this.$my.other.msg({
						type:'warning',
						str:'此运单未发车,不能查看轨迹'
					})
					return;
				}
				this.trajectory.case_county=item.case_county;
				this.trajectory.aim_county=item.aim_county;
				this.trajectory.start_time=parseInt(item.start_time);
				this.trajectory.end_time=item.status==2?parseInt(new Date().getTime()/1000):parseInt(item.arrived_time);
				this.trajectory.truck_plate_num=item.truck_plate_num;
				this.trajectory.is_show=true;
			},
			truck_trajectory_close(){
				this.trajectory.is_show=false;
				this.trajectory.case_county=null;
				this.trajectory.aim_county=null;
				this.trajectory.start_time=null;
				this.trajectory.end_time=null;
				this.trajectory.truck_plate_num=null;
			},

			//打开运单参数界面
			tord_set_open(item){

				//置入运单编号
				this.tord_set.truck_tord_num=item.truck_tord_num

				//打开
				this.tord_set.is_show++
			},

			//上报记录
			tord_upl_open(item){
				this.upl_list.tord_info={
					truck_tord_num:item.truck_tord_num,
					truck_plate_num:item.truck_plate_num,
					upl_status:item.upl_status,
					start_time:item.start_time,
					arrived_time:item.arrived_time,
					case_code:item.case_other_obj.county_code,
					aim_code:item.aim_other_obj.county_code,
				}
				this.upl_list.is_show++
			},

			//运单锁定相关
			tord_lock_open(item){//打开运单锁定界面
				this.lock.truck_tord_num=item.truck_tord_num
				this.lock.is_show++
			},
			tord_lock_close(obj){//关闭运单锁定界面
				
				//锁定原因
				if(!obj.lock_reason.trim()){
					this.$my.other.msg({
						type:"warning",
						str:'缺少锁定原因'
					});
					return;
				}

				//提交
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_lock_by_admin',
						lock_reason:obj.lock_reason.trim(),
						truck_tord_num:this.lock.truck_tord_num,
					},
					callback:(data)=>{

						this.$my.other.msg({
							type:"success",
							str:'锁定成功'
						});

						//关闭弹出层
						this.lock.is_show=0
	
						//刷新本页
						this.get_page_data()
					},
				});
			},
			tord_unlock(item){//运单解锁

				//提示
				this.$my.other.confirm({
					content:"您的操作是'运单解锁',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_unlock_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								this.$my.other.msg({
									type:"success",
									str:'锁定成功'
								});

								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//运费相关操作
			freight_edit_open(item){//打开运费界面
				this.freight_edit.is_show++;
				this.freight_edit.tord_info={...item}
			},
			freight_edit_close(){//关闭运费界面
				this.freight_edit.is_show=0
				this.freight_edit.tord_info={}

				//刷新
				this.get_page_data()
			},

			//前往支付记录
			tord_payed_list(item,aim){
				
				//跳转
				this.$router.push({
					path:'/pages/transit_real_time/'+aim,
					query:{
						truck_tord_num:item.truck_tord_num
					}
				});
			},

			//打开结算界面
			settle_view_open(item){

				//置入运单信息
				this.settle.tord_info=item

				//打开结算界面
				this.settle.is_show++;
			},
			settle_view_close(){

				//关闭结算界面
				this.settle.is_show=0;

				//清空运单信息
				this.settle.tord_info={}
			},

			//完结相关操作
			tord_edit_sub(item){
				
				//关闭弹出层
				this.edit.is_show=0;

				//刷新
				this.get_page_data()
			},
			tord_edit(item){
				this.edit.is_show++;
				this.edit.tord_info=item
			},
			
			//凭证相关操作
			voucher_show(item){//显示凭证
				this.voucher.is_show++;
				this.voucher.truck_tord_num=item.truck_tord_num
			},

			//取消运单
			tord_cancel(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'取消运单',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_act_cancel_by_admin',
								truck_tord_nums:[item.truck_tord_num],
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//确认到货
			tord_arrived(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认到货',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_arrived_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//确认发车
			tord_start(item){
				
				//提示
				this.$my.other.confirm({
					content:"您的操作是'确认发车',请确认",
					confirm:()=>{
			
						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_start_by_admin',
								truck_tord_num:item.truck_tord_num,
							},
							callback:(data)=>{
			
								//刷新本页
								this.get_page_data()
							},
						});
					}
				});
			},
			
			//更多条件
			ser_form_open(){//打开

				this.ser_form_is_show++
			},
			ser_form_sub(obj){//提交

				//置入表单数据
				for(var key in obj){

					if(!obj[key]){
						continue;
					}

					this.form[key]=obj[key];

					//特殊处理
					switch(key){

						case 'cargo_creat_time'://货源创建时间
							this.form.cargo_creat_time_arr=[
								(obj.cargo_creat_time[0].getTime() / 1000).toFixed(0),
								(obj.cargo_creat_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'creat_time'://成交时间(运单创建时间)
							this.form.creat_time_arr=[
								(obj.creat_time[0].getTime() / 1000).toFixed(0),
								(obj.creat_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'start_time'://发车时间
							this.form.start_time_arr=[
								(obj.start_time[0].getTime() / 1000).toFixed(0),
								(obj.start_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'arrived_time'://到货时间
							this.form.arrived_time_arr=[
								(obj.arrived_time[0].getTime() / 1000).toFixed(0),
								(obj.arrived_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'payed_time'://支付完成时间
							this.form.payed_time_arr=[
								(obj.payed_time[0].getTime() / 1000).toFixed(0),
								(obj.payed_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'invoiced_time'://开票完成时间
							this.form.invoiced_time_arr=[
								(obj.invoiced_time[0].getTime() / 1000).toFixed(0),
								(obj.invoiced_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'settled_time'://结算完成时间
							this.form.settled_time_arr=[
								(obj.settled_time[0].getTime() / 1000).toFixed(0),
								(obj.settled_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'tord_upld_time'://运单上报时间
							this.form.tord_upld_time_arr=[
								(obj.tord_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.tord_upld_time[1].getTime() / 1000).toFixed(0),
							];break;

						case 'outcash_upld_time'://出金流水上报时间
							this.form.outcash_upld_time_arr=[
								(obj.outcash_upld_time[0].getTime() / 1000).toFixed(0),
								(obj.outcash_upld_time[1].getTime() / 1000).toFixed(0),
							];break;
					}
				}

				//关闭页面
				this.ser_form_is_show=0

				//读取数据
				this.page_ser()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},
			
			//清空查询条件
			ser_para_clear(){
				this.form={
					
					//运单编号相关
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游运单编号
					bl_num:'',//线路编号
					group1_num:'',//组别编号

					//货主相关
					shipper_tel:'',//货主手机号
					shipper_user_name:'',//货主姓名
					shipper_company_name:'',//货主公司名称

					//车主/司机/车辆
					truck_owner_tel:'',//车主手机号
					truck_owner_name:'',//车主姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					truck_plate_num:'',//车牌号

					//状态相关
					status:'',//状态(1:待发车,2:运输中,3:已到货)
					lock_status:'',//锁定状态(1:已锁定,2:未锁定/正常)
					payed_status:'',//支付状态(1:未支付,2:部分支付,3:支付完毕)
					invoiced_status:'',//开票状态(1:未开票,2:部分开票,3:开票完毕)
					settled_status:'',//结算状态(1:未结算,2:部分结算,3:结算完毕)
					upl_status:'',//上报状态(1:未上报,2:运单上报成功,3:运单上报失败,4:出金流水上报成功,5:出金上报失败)
					upl_aim:'',//运单上报目标,上报后有值(类似:zhejiang;anhui....)

					//货源数据相关
					cargo_name:'',//货物名称
					cargo_type:'',//货物类型
					settlement_type:'',//结算方式
					is_need_return:'',//是否回程
					match_way:'',//匹配方式(1:车主抢单,2:货主指定后车主接单,3:车主竞价,4:车主扫码接单,5:网点线下匹配后报单,6:合作方协助匹配后报单,7:后台匹配,8:后台添加)
					outlets_num:'',//网点编号

					//货车类型相关
					need_truck_type:'',//租用货车类型
					need_truck_type2:'',//租用货车细分类型
					need_truck_types:null,//货车类型

					//发到货地相关
					case_prov:'',//装货地省份
					case_city:'',//装货地城市
					case_county:'',//装货地县区
					aim_prov:'',//到货地省份
					aim_city:'',//到货地城市
					aim_county:'',//到货地县区
					case_cities:null,//装货地省市县
					aim_cities:null,//到货地省市县

					//时效相关
					cargo_creat_time:null,//货源创建时间
					creat_time:null,//成交时间(运单创建时间)
					start_time:null,//发车时间
					arrived_time:null,//到货时间
					payed_time:null,//支付完成时间
					invoiced_time:null,//开票完成时间
					settled_time:null,//结算完成时间
					tord_upld_time:null,//运单上报时间
					outcash_upld_time:null,//出金流水上报时间
				}

				//读取
				this.get_page_data()
			},

			//导出搜索结果
			tord_export_open(){//打开界面

				//置入条件
				this.tord_export.rull=this.form

				//打开界面
				this.tord_export.is_show++
			},
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_act_list_admin',
						is_get_shipper_info:1,
						is_get_shipper_company_info:1,
						is_get_truck_owner_info:1,
						is_get_driver_info:1,
						is_get_truck_info:1,
						is_get_tord_outlets:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//总金额
						this.list.total_freight = data.total.total_freight

						//总服务费
						this.list.total_service = data.total.total_service
						//预处理
						for(let item of data.list){

							//成交时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);
							
							//发车时间
							if(item.start_time>0){//已发车
								item.start_time_text=this.$my.other.totime(item.start_time);
							}else item.start_time_text="未发车";
							
							//到货时间
							if(item.arrived_time>0){//已到货
								item.arrived_time_text=this.$my.other.totime(item.arrived_time);
							}else item.arrived_time_text="未到货";

							//装货联系人
							if(item.case_link_man){
								item.case_link_man_text=item.case_link_man;
							}else item.case_link_man_text="无";

							//装货联系电话
							if(item.case_link_tel){
								item.case_link_tel_text=item.case_link_tel;
							}else item.case_link_tel_text="无";

							//卸货联系人
							if(item.aim_link_man){
								item.aim_link_man_text=item.aim_link_man;
							}else item.aim_link_man_text="无";

							//卸货联系电话
							if(item.aim_link_tel){
								item.aim_link_tel_text=item.aim_link_tel;
							}else item.aim_link_tel_text="无";

							/* 装货地其他数据(json) */
							item.case_other_obj=JSON.parse(item.case_other);

							//装货时间
							if(!item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//只有止点

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_end)+"以前";

							}else if(item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//只有起点

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+"以后";

							}else if(item.case_other_obj.case_time_start && item.case_other_obj.case_time_end){//都有

								item.case_other_obj.case_date=this.$my.other.todate(item.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.case_other_obj.case_time_end);

							}else if(!item.case_other_obj.case_time_start && !item.case_other_obj.case_time_end){//都没有

								item.case_other_obj.case_date="未设置";
							}

							//发车时间
							if(!item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//只有止点

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_2)+"以前";

							}else if(item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//只有起点

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+"以后";

							}else if(item.case_other_obj.start_time_1 && item.case_other_obj.start_time_2){//都有

								item.case_other_obj.start_time=this.$my.other.todate(item.case_other_obj.start_time_1)+" ~ "+this.$my.other.todate(item.case_other_obj.start_time_2);

							}else if(!item.case_other_obj.start_time_1 && !item.case_other_obj.start_time_2){//都没有
								
								item.case_other_obj.start_time="未设置";
							}

							//发车时间是否强制校验
							switch(item.case_other_obj.start_time_is_check){
								case '1':item.case_other_obj.start_time_is_check_text="(强制)";break;
								case '2':item.case_other_obj.start_time_is_check_text="(不强制)";break;
							}
							if(item.case_other_obj.start_time=="未设置"){//如果未设置发车时间,则不存在强制不强制
								item.case_other_obj.start_time_is_check_text=""
							}

							/* 卸货地其他数据(json) */
							item.aim_other_obj=JSON.parse(item.aim_other);

							//卸货时间
							if(!item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//只有止点

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_end)+"以前";

							}else if(item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//只有起点

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+"以后";

							}else if(item.aim_other_obj.aim_time_start && item.aim_other_obj.aim_time_end){//都有

								item.aim_other_obj.aim_date=this.$my.other.todate(item.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.aim_other_obj.aim_time_end);

							}else if(!item.aim_other_obj.aim_time_start && !item.aim_other_obj.aim_time_end){//都没有
								
								item.aim_other_obj.aim_date="未设置";
							}

							//运单状态
							switch(item.status){
								case '1':item.status_text="待发车";break;
								case '2':item.status_text="运输中";break;
								case '3':item.status_text="已到货";break;
							}

							//锁定状态
							switch(item.lock_status){
								case '1':item.lock_status_text="已锁定";break;
								case '2':item.lock_status_text="未锁定";break;
							}

							//结算方式
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}
							
							//是否回程
							switch(item.is_need_return){
								case '1':item.is_need_return_text="需要回程";break;
								case '2':item.is_need_return_text="不需要回程";break;
							}

							//匹配方式
							switch(item.match_way){
								case '1':item.match_way_text="车主抢单";break;
								case '2':item.match_way_text="指定后车主接单";break;
								case '3':item.match_way_text="车主竞价中标";break;
								case '4':item.match_way_text="车主扫码接单";break;
								case '5':item.match_way_text="网点线下匹配";break;
								case '6':item.match_way_text="合作方匹配";break;
								case '7':item.match_way_text="后台匹配";break;
								case '8':item.match_way_text="后台添加";break;
							}

							//应收总额
							if(item.cost_info.freight_total){
								item.cost_info.freight_total_text=item.cost_info.freight_total+" 元";
							}else item.cost_info.freight_total_text="未设置";

							//应付总额
							if(item.cost_info.give_carrier_total){
								item.cost_info.give_carrier_total_text=item.cost_info.give_carrier_total+" 元";
							}else item.cost_info.give_carrier_total_text="未设置";

							//已付总额
							item.cost_info.has_payed_total_text=item.cost_info.has_payed_total+" 元"

							//运费支付状态
							switch(item.payed_status){
								case '1':item.payed_status_text="未支付";break;
								case '2':item.payed_status_text="部分支付";break;
								case '3':item.payed_status_text="支付完毕";break;
							}

							//运费结算状态
							switch(item.settled_status){
								case '1':item.settled_status_text="未结算";break;
								case '2':item.settled_status_text="部分结算";break;
								case '3':item.settled_status_text="结算完毕";break;
							}

							//货车类型
							item.truck_info.truck_type_name_text=`
								${item.truck_info.truck_type_name?'/'+item.truck_info.truck_type_name+'/':''}
								${item.truck_info.truck_type_name2?item.truck_info.truck_type_name2:''}
							`;

							//上报
							switch(item.upl_status){
								case '1':item.upl_text="未上报";break;
								case '2':item.upl_text="运单上报成功";break;
								case '3':item.upl_text="运单上报失败";break;
								case '4':item.upl_text="流水上报成功";break;
								case '5':item.upl_text="流水上报失败";break;
							}
							if(item.upl_aim)item.upl_text+=`(${this.logink_aim_list[item.upl_aim]})`
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		span {
			margin-left: 20px;
		}
	}
</style>